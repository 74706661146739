import type { AddEthereumChainParameter } from '@web3-react/types';
import { ChainId } from './enum';

const infuraKey = process.env.INFURA_API_KEY;
export const expectedChainId = 137;

export const mcontractAddresses : {
  [chainId: number]:any 
} =  {
 [ChainId.Polygon]:{
   colletions:[
    '0xb9f382d0b8b7345f2f0d2f7510ee121dc75bee03',
    '0x69cE2CAE837a7F8B01Cb66db560f31E2ae3A51aE',
    '0x939821Fd096b4e4F67f369AF67cf9411b1A28160',
    '0xD5cA946AC1c1F24Eb26dae9e1A53ba6a02bd97Fe',
    '0xD27029e4EBc3c4C55fCfAdDDC54fa0B911829AFC',
    '0x9E8Ea82e76262E957D4cC24e04857A34B0D8f062',
    '0x83a5564378839EeF0721bc68A0fbeb92e2dE73d2',
    '0x2953399124F0cBB46d2CbACD8A89cF0599974963',
    '0x080B41b35b47587A85383aDfaB2449ba145eb195',
    '0x4d544035500D7aC1B42329c70eb58E77f8249f0F',
    '0x22d5f9B75c524Fec1D6619787e582644CD4D7422',
    '0xa54999a6C1380164eccd4727f5E7a5bfe2470D44',
    '0x473989BF6409D21f8A7Fdd7133a40F9251cC1839'
  ]}, 
};

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls as string[],
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  [ChainId.ETH]: {
    urls: [
      infuraKey ? `https://mainnet.infura.io/v3/${infuraKey}` : undefined,
      process.env.alchemyKey
        ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
        : undefined,
      'https://cloudflare-eth.com',
    ].filter((url) => url !== undefined) as string[],
    name: 'Mainnet',
  }, 
  [ChainId.Base]: {
    urls: [
      infuraKey ? `https://Base.infura.io/v3/${infuraKey}` : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Base',
  }, 
  // Optimism
  [ChainId.Optimism]: {
    urls: [
      infuraKey
        ? `https://optimism-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://mainnet.optimism.io',
    ].filter((url) => url !== undefined) as string[],
    name: 'Optimism',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  }, 
  // Arbitrum
  [ChainId.Arbitrum]: {
    urls: [
      infuraKey
        ? `https://arbitrum-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://arb1.arbitrum.io/rpc',
    ].filter((url) => url !== undefined) as string[],
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  }, 
  // Polygon
  [ChainId.Polygon]: {
    urls: [
      infuraKey
        ? `https://polygon-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://polygon-rpc.com',
    ].filter((url) => url !== undefined) as string[],
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  }, 
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
