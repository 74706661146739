import { 
    Avatar, 
    Box, 
    FormControl, 
    ListItemIcon, 
    ListItemText,
    MenuItem,  
    Select,
    Stack,
    Typography, 
  } from '@mui/material'; 
  import { NETWORKS } from '../constants/chain';
  import { Network } from '../types/chains';  
import { toast } from 'react-toastify';

  export default function ChainSelect({
  activeChainId,
  switchChain
}: { 
  activeChainId?: number ,
  switchChain: (chainId: number) => void 
}) {
  return ( 
    <FormControl>
    <Select
      fullWidth
      value={activeChainId||137}
      onChange={(event) => { 
        if(event.target.value!='137')
        {
          toast.error("Comming soon ,now surpport polygon only!"); 
        }
        switchChain(137)
      }}
      name="chainId"
      renderValue={(value) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            alignContent="center"
            spacing={1}
          >
            <Avatar
              src={NETWORKS[Number(activeChainId)]?.imageUrl || ''}
              style={{ width: '1rem', height: '1rem' }}
            />
            <Typography variant="body1">
              {NETWORKS[Number(activeChainId)].name}
            </Typography>
          </Stack>
        );
      }}
    >
      {Object.keys(NETWORKS)
        .filter((key) => NETWORKS[Number(key)].surrportnet)
        .map((key: any, index: number) => (
          <MenuItem key={index} value={key}>
            <ListItemIcon>
              <Box
                sx={{
                  width: (theme) => theme.spacing(4),
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  src={(NETWORKS[key] as Network)?.imageUrl|| ''}
                  sx={{
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              </Box>
            </ListItemIcon>
            <ListItemText primary={NETWORKS[key].name} />
          </MenuItem>
        ))}
    </Select>
  </FormControl> 
  )
}