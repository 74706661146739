export enum SwapSteps {
  Start,
  StartApproval,
  WaitingWalletApproval,
  FinishApproval,
  StartSwap,
  WaitingWalletSwap,
  FinishSwap,
}

export enum TraderOrderStatus {
  Open = 'open',
  Filled = 'filled',
  Expired = 'expired',
  Cancelled = 'cancelled',
  All = 'all',
}

export enum TraderOrderVisibility {
  Public = 'public',
  Private = 'private',
}

export enum NetworkName {
  ETH = 'eth',
  BASE = 'base',
  POLYGON = 'polygon', 
  ARBITRUM = 'arbitrum', 
  OPTMISM = 'optimism'
}

export enum ChainId {
  ETH = 1,
  Base = 8453,  
  Polygon = 137,  
  Optimism = 10, 
  Arbitrum = 42161, 
}

export enum NFTType {
  ERC1155 = 'ERC1155',
  ERC721 = 'ERC721',
}

export enum SellOrBuy {
  All = 'all',
  Sell = 'sell',
  Buy = 'buy',
}
