import { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import Button from '@mui/material/Button'; 
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'; 
import IconButton from '@mui/material/IconButton';  
import WalletIcon from '@mui/icons-material/Wallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'; 
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar,List, ListItemButton, ListItemIcon, ListItemText,Popover } from '@mui/material'; 
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { getNetworksurrportnetFromChainId, getWalletIcon, truncateAddress } from '../utils/blockchain';   
import { useSwitchNetworkMutation } from '../hooks/blockchain';
import ChainSelect from './ChainSelect'; 

const WalletMenuMobile = () => { 
  const { isActive, account, connector, chainId ,provider} = useWeb3React(); 
  //const tokenBalancesQuery = useERC20BalancesQuery();
  const [openmenu, setOpenmenu] = useState(null); 
  const nagate= useNavigate();
  const switchNetworkMutation = useSwitchNetworkMutation();  
  
  const mchainid = getNetworksurrportnetFromChainId(chainId)?chainId:137;

  const handlemenuOpen = (event: any) => {
    setOpenmenu(event.currentTarget);
  }; 
  const handlemenuClose = () => {
    setOpenmenu(null);
  };  
  ///const NativeBalance = useNativeBalanceQuery();
/*   const erc20Balance = useErc20Balance(
    provider,
    form.values.tokenAddress,
    account
  ); */




  // const { token, balance } = tokenBalancesWithPrices;

  // const balanceUnits = ethers.utils.formatUnits(balance || '0', token.decimals);
  // console.log('NativeBalance.data?.balance;',NativeBalance.data?.balance)

  const handlenetworkOpen  = async (chainId: number) => {  
    if (chainId) { 
     // console.log('handlenetworkOpen:',chainId) 
      await switchNetworkMutation.mutateAsync({chainId:Number(chainId)}); 
    } 
    handlemenuClose();
  }; 


  const Navigato = (ds:string) => {
        handlemenuClose();
        nagate(ds); 
  };

/*   useEffect(() => {
    try {
      if (connector?.connectEagerly) {
        connector.connectEagerly();
        handlemenuClose();
      }
    } catch(error) {
      handlemenuClose();
      console.log(error);
    }
  }, []); // eslint-disable-line  */

  const disconnect = () => {
    if (connector?.deactivate) {
      try {
        connector.deactivate();
      } catch (error) {
        console.log(error);
      }
    } else {
      void connector.resetState()
    }
  }
/*   const tokenBalancesWithPrices =  useMemo(() => {
    return tokenBalancesQuery?.data?.map((tb) => {
      return {
        ...tb,
      };
    });
  }, [tokenBalancesQuery.data]);
 */



  return (
         <>  
           <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>  
                    <Avatar   src="/assets/images/avatars/avatar_1.jpg" /> 
                    <Typography variant="subtitle2"> {truncateAddress(account) }</Typography>
                  </Box>  
                </Box>
                <SimpleBar  >
               {/*  <Divider sx={{ borderStyle: 'dashed' }} />
                {!tokenBalancesWithPrices ? (
                <Skeleton variant="rectangular" width={210} height={60} />
                ):(
                <Box  >  
                <RendertokenRows 
                  tokendata={tokenBalancesWithPrices}
                  tkchainid={mchainid} 
                 />
                </Box>  
                  )} */}

                <Divider sx={{ borderStyle: 'dashed' }} /> 
                <Box sx={{ height: { xs: 340, sm: 'auto' } }}> 
                  <List   > 
                  <ListItemButton onClick={() =>
                    Navigato('/mywallet')}>
                    <ListItemIcon>
                      <WalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="My wallet" />
                  </ListItemButton> 
                  <ListItemButton onClick={() =>
                    Navigato('/account/profile/'+account+'&mycolletions')}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My assets" />
                  </ListItemButton>
                  <ListItemButton onClick={() =>
                    Navigato('/mywhitelistspagev2')}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="My whitelists" />
                  </ListItemButton>
                  <ListItemButton onClick={() =>
                    Navigato('/account/edit')}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit/reg profile" />
                  </ListItemButton>
                  <ListItemButton onClick={() =>
                    Navigato('/createcolletionv2')}>
                    <ListItemIcon>
                      <CreateNewFolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create colletion" />
                  </ListItemButton> 
                  <ListItemButton onClick={() =>
                    Navigato('/createnftv2')}>
                    <ListItemIcon>
                      <CreateNewFolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create NFT" />
                  </ListItemButton> 
                  </List> 
                </Box>
              </SimpleBar>
                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box  >
                  <Button   startIcon={<LogoutIcon />} fullWidth disableRipple onClick={disconnect}>
                    <FormattedMessage id="logout.wallet" defaultMessage="Logout wallet" />
                  </Button>
                </Box> 
       </>
  );
}

export default WalletMenuMobile;
