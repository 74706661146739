  
import React, { useMemo, useRef,useState ,useContext} from 'react'; 
import { Icon } from "@iconify/react";
import { useWeb3React } from "@web3-react/core";
import { NotificationManager } from 'react-notifications';   
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';   
import {
  AppBar,
  Typography,
  Avatar,
  Badge,
  Toolbar,
  Box,
  IconButton,
  Button,
  ButtonBase,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuIcon,
  NoSsr,
  Popover,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'; 
import SettingsIcon from '@mui/icons-material/Settings';  
import {
  getChainLogoImage,
  getChainName,
  getWalletIcon,
  truncateAddress,
} from '../../utils/blockchain'; 

import { Language } from '@mui/icons-material';
import AttachMoney from '@mui/icons-material/AttachMoney';
import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { FormattedMessage } from 'react-intl';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MenuRoutes } from "./../../_mock/MenuItems";
import useResponsive from "./../../hooks/useResponsive";
import DropdownMenu from "./../../components/DropdownMenu";
import MobileDrawer from "./MobileDrawer"; 
import useSettings from "./../../hooks/useSettings"; 
import WalletMenu from '../../components/WalletMenu'; 
import Wallet from '../../components/icons/Wallet';
import { useConnectWalletDialog } from '../../hooks/app';

export default function MainMenu() { 
    const buttonRef = useRef<HTMLElement | null>(null);  
    const { isActive, connector, account, chainId, ENSName } = useWeb3React(); 
    const connectWalletDialog = useConnectWalletDialog();   
    const handleOpenConnectWalletDialog = () => {
      connectWalletDialog.setOpen(true);
    };
    const { themeMode, onToggleMode } = useSettings();   
    const isMobile = useResponsive('down', 'md');
    const [open, setOpen] = useState(false); 
    const openDrawer = () => {
        setOpen(true); 
    }
    const handleClose = () => {
        setOpen(false);
    } 
    return (
        <Stack direction='row' gap={2} alignItems="center" >
            {!isMobile && MenuRoutes.map((menu, index) => (
                <DropdownMenu key={index} menu={menu} />
            ))}  
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              alignContent="center"
            >
             { isActive && account ? (   
                <WalletMenu /> 
                ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                    variant="outlined" 
                    color='primary'
                    onClick={handleOpenConnectWalletDialog}
                    startIcon={<Wallet />}
                    endIcon={<ChevronRightIcon />}  >
                    Wallet
                </Button>
            </Stack>
                )
            }
            </Stack> 
            <IconButton onClick = {onToggleMode} sx = {{color:'black', padding:'0px'}}>
                <Icon  color = {themeMode === 'light' ?'black':'white'} icon={themeMode === 'light' ? "ic:twotone-nightlight" : "carbon:light-filled"} width={20} />
            </IconButton> 
            {isMobile &&
               <><IconButton onClick={openDrawer}>
                    <Icon icon='dashicons:menu-alt' />
                </IconButton><MobileDrawer    isActive={isActive}    account={account}    open={open} handleClose={handleClose} />
                </> 
            } 
            
        </Stack>
    )
}