import { Network } from '../types/chains';
import { ChainId } from './enum';

const avaxIcon = '../assets/images/icons/avax.png';
const bscIcon = '../assets/images/icons/bnb.svg';
const optimismIcon = '../assets/images/icons/optimism.svg';
const fantomIcon = '../assets/images/icons/fantom.svg';
const ethIcon = '../assets/images/icons/eth.png';
const polygonIcon = '../assets/images/icons/polygon.png';
const arbitrumIcon = '../assets/images/icons/arbitrum.png';

const infuraKey = process.env.INFURA_API_KEY;

export const NETWORKS: { [key: number]: Network } = {
  [ChainId.ETH]: {
    chainId: ChainId.ETH,
    symbol: 'ETH',
    explorerUrl: 'https://etherscan.io',
    name: 'Ethereum',
    slug: 'ethereum',
    rpcname: 'eth-mainnet',
    coingeckoId: 'ethereum',
    wrappedAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    imageUrl: ethIcon,
    providerRpcUrl: `https://rpc.ankr.com/eth`,
    testnet: false,
    surrportnet: true,
  }, 
  [ChainId.Polygon]: {
    chainId: ChainId.Polygon,
    symbol: 'MATIC',
    explorerUrl: 'https://polygonscan.com',
    name: 'Polygon',
    slug: 'polygon',
    rpcname: 'polygon-mainnet',
    coingeckoId: 'matic-network',
    wrappedAddress: `0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270`,
    providerRpcUrl: `https://polygon-rpc.com/`,
    imageUrl: polygonIcon, 
    testnet: false,
    surrportnet: true,
  }, 
  [ChainId.Optimism]: {
    chainId: ChainId.Optimism,
    symbol: 'OP',
    nativeCurrencyUrl: ethIcon,
    explorerUrl: 'https://optimistic.etherscan.io',
    name: 'Optimism',
    slug: 'optimism',
    rpcname: 'opt-mainnet',
    coingeckoId: 'ethereum',
    wrappedAddress: '0x4200000000000000000000000000000000000006',
    imageUrl: optimismIcon,
    providerRpcUrl: 'https://mainnet.optimism.io',
    nativeCurrency: {
      name: 'Ethereum',
      decimals: 18,
      symbol: 'ETH'
    },
    testnet: false,
    surrportnet: true,
  },
  [ChainId.Arbitrum]: {
    chainId: ChainId.Arbitrum,
    symbol: 'ARB',
    explorerUrl: 'https://arbiscan.io/',
    name: 'Arbitrum',
    slug: 'arbitrum',
    rpcname: 'arb-mainnet',
    coingeckoId: 'ethereum',
    wrappedAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    nativeCurrencyUrl: ethIcon,
    imageUrl: arbitrumIcon,
    providerRpcUrl: 'https://arb1.arbitrum.io/rpc',
    nativeCurrency: {
      name: 'Ethereum',
      decimals: 18,
      symbol: 'ETH',
    },
    testnet: false,
    surrportnet: true,
  },
  [ChainId.Base]: {
    chainId: ChainId.Base,
    symbol: 'ETH',
    explorerUrl: 'https://basescan.org/',
    name: 'Base',
    slug: 'base',
    rpcname: 'base-mainnet',
    coingeckoId: 'ethereum',
    wrappedAddress: '0x4200000000000000000000000000000000000006',
    nativeCurrencyUrl: ethIcon,
    imageUrl: ethIcon,
    providerRpcUrl: 'https://mainnet.base.org',
    nativeCurrency: {
      name: 'Ethereum',
      decimals: 18,
      symbol: 'ETH',
    },
    testnet: false,
    surrportnet: true,
  }, 
};
