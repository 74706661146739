export const MenuRoutes = [
    {
        title:'Home',
        path:'/'
    }, 
    {
        title:'Ecosystem',
        path:'/ecosystem',
        elements:[ 
            {title:'ZenoDigiArt (ERC721)',path:'https://zenodigiart.pages.dev', outLink:true},
            {title:'ZenoNexus (ERC1155)',path:'https://zenonexus.pages.dev', outLink:true},
            {title:'ZenoGiving (ERC1155)',path:'/comming'},
            {title:'ZenoMedia (ERC1155)',path:'/comming'},
            {title:'ZenoBook (ERC1155)',path:'/comming'},
        ]
    }, 
    {
        title:'ZenoPASS',
        path:'/membershipspagev2',
        // elements:[
        //     {title:'ZenoPASS',path:'/membershipspagev2'},
        //     {title:'My whitelists',path:'/mywhitelistspagev2'},
        //     {title:'Create Colletion',path:'/createcolletionv2'},
        //     {title:'Create NFT',path:'/createnftv2'}, 
        // ]
    }, 
    {
        title:'About Us',
        path:'/aboutus' 
    }
]
